const get = require('lodash/get')
const { listAll } = require('../../location/api/read/list-all')
const createGraph = require('../../relationship-graph')
const submitPartial = require('./submit-partial')
const getOverview = require('./get-overview')
const { previousReportingPeriod } = require('../../tools')

module.exports = async function getOverviews (state, {
  locationId,
  date,
  programs,
  features = {
    partialCounts: false,
    canSeeReports: false,
    opensFuture: false,
    opensPrevious: false
  }
} = {},
config
) {
  // Prefetch all locations so that not all overiew.get calls do it again
  const allLocations = await listAll(state, { date })
  const graph = createGraph(allLocations)

  // load sequentially
  const overviews = []
  for (const p of programs) {
    overviews.push(
      await getOverview(state, { locationId, program: p, date, allLocations, graph }, config)
    )
  }

  /*
   * When partial stock counts are enabled, check which locations can submit those:
   */
  if (features.partialCounts) {
    await Promise.all(
      overviews.map(async overview => {
        const submitPartials = await submitPartial(state, {
          locations: overview.locations.all,
          date: date,
          programId: overview.id
        })

        // Mutate overview response:
        overview.locations.all.forEach(location => {
          // Partial counts only supported on single-service locations right now
          if (location.services.length > 1) {
            return
          }
          location.submitPartial = get(submitPartials, `${location._id}.submitPartial`, false)
        })
      })
    )
  }

  overviews.forEach(o => {
    // Active: the one in entry now, current: the period displayed
    const isFuture = o.periods.active.id <= o.periods.current.id
    const isRightBeforeTheActive = o.periods.current.id === previousReportingPeriod('monthly', o.periods.active.id)
    const canSee =
      features.canSeeReports ||
      (isFuture && features.opensFuture) ||
      (isRightBeforeTheActive && features.opensPrevious)

    if (canSee) {
      o.periods.current.isEditable = canSee
    }
  })

  return overviews
}
