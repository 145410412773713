import { hasFeature } from '../../../../van-shared/utils/features'
import { userIsAuthorised } from '../../../../van-shared/utils/auth'

import {
  HAS_NO_DATA,
  IS_LOADING,
  LOADING_FAILED,
  LOADING_SUCCEEDED
} from '../utils'

const reducerName = 'overview'

export const REQUEST_OVERVIEW = `${reducerName}/REQUEST_OVERVIEW`
export const RECEIVE_OVERVIEW = `${reducerName}/RECEIVE_OVERVIEW`
export const USE_CACHE = `${reducerName}/USE_CACHE`
export const RECEIVE_ERROR = `${reducerName}/RECEIVE_ERROR`
export const RESET = `${reducerName}/RESET`

export const defaultState = {
  status: HAS_NO_DATA,
  overviews: null,
  lastOptions: null
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case REQUEST_OVERVIEW: return {
      ...state,
      status: IS_LOADING,
      lastOptions: action.options
    }
    case RECEIVE_OVERVIEW: return {
      ...state,
      status: LOADING_SUCCEEDED,
      overviews: action.overviews
    }
    case USE_CACHE: return {
      ...state,
      status: LOADING_SUCCEEDED
    }
    case RECEIVE_ERROR: return {
      ...state,
      status: LOADING_FAILED,
      overviews: null
    }
    case RESET: return {
      ...defaultState
    }
    default: return state
  }
}

export const reset = () => {
  return {
    type: RESET
  }
}

export const fetchOverview = (options, config) => async (dispatch, getState, { user, api }) => {
  const { locationId, programId = null } = options
  const date = new Date(options.date)

  const state = getState()
  const status = state.overview.status
  const lastOptions = state.overview.lastOptions

  if (status === IS_LOADING) {
    return
  }

  if (lastOptions &&
      options.locationId === lastOptions.locationId &&
      options.programId === lastOptions.programId &&
      options.date === lastOptions.date) {
    return dispatch({type: USE_CACHE})
  }
  dispatch({ type: REQUEST_OVERVIEW, options })

  const programs = await api.program.list()
  let userPrograms = programs.filter(p => user.programs.includes(p.id))
  if (programId) {
    userPrograms = userPrograms.filter(p => p.id === programId)
  }

  // Check if we already have the requested overviews loaded
  const existingOverviews = state.overview.overviews
  if (existingOverviews) {
    const alreadyLoaded = userPrograms.every(program => {
      const overview = existingOverviews.find(o => o.id === program.id)
      if (!overview) {
        return false
      }
      if (locationId !== overview.locationId) {
        return false
      }
      if (date < overview.periods.current.entryStartDate ||
          date > overview.periods.current.entryEndDate) {
        return false
      }
      return true
    })
    if (alreadyLoaded) {
      return dispatch({type: USE_CACHE})
    }
  }

  const partialCounts = hasFeature(config.features, 'stockCount.adjustments')

  // This will set isEditablePeriod to true
  // so you can edit all reports from the locations/services view
  const opensExisting = hasFeature(config.features, 'stockCount:opensExistingReport')
  const opensFuture = hasFeature(config.features, 'stockCount:opensFutureReport')
  const userEditsAny = userIsAuthorised(user, 'feature:edits-any-reporting-period')
  const hasReadOnlyReports = userIsAuthorised(user, 'feature:read-only-reports')
  const canSeeReports = (opensExisting || userEditsAny || hasReadOnlyReports)
  const opensPrevious = hasFeature(config.features, 'stockCount:editsPreviousReport')

  const overviews = await api.report.overview.getMany({
    locationId,
    programs: userPrograms,
    date,
    features: {
      partialCounts,
      canSeeReports,
      opensFuture,
      opensPrevious
    }
  }, config)

  dispatch({ type: RECEIVE_OVERVIEW, overviews })
}
