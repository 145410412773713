import React from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import get from 'lodash/get'

import { Accordion, Banner, Button, Page, StatusPill, Text, UppercaseLabel } from '@fielded/shared-ui'
import capitalize from '@fielded/shared-ui/src/utils/capitalize'
import { Home, Truck } from '@fielded/shared-ui/src/icons'
import { SHIPMENT_STATUS, SHIPMENT_STATUS_TO_LMD_DISPLAY_STATUS } from '@fielded/fs-api/lib/shipment/constants'

import { formatDate } from '../../../../../van-shared/utils/utils'
import { getPSMDriverDeliveryTitle } from '../../../shipments/psm-shipments/PSMDriverDelivery/utils'
import { PERMISSION_STATES } from '../../../../../utils/permissions-service'
import DeliveryDetailProduct from '../../../../retailer/shipments/DeliveryDetail/DeliveryDetailProduct'
import DriverDeliveryPermissionModal from '../../../shipments/psm-shipments/PSMDriverDelivery/component/DriverDeliveryPermissionModal'
import PSMDeliveryAuthenticationContainer from '../../../shipments/psm-shipments/PSMDriverDelivery/PSMDeliveryLocation/PSMDeliveryAuthentication/PSMDeliveryAuthenticationContainer'

const FacilityDeliveryDetail = ({
  api,
  otp,
  otpKey,
  skipGPSValidation,
  isBottomDrawerOpen,
  permission,
  geoPosition,
  position,
  distance,
  locationObject,
  positionErrorMsg,
  onCloseBottomDrawer,
  isPermissionModalOpen,
  isRequestingPermission,
  onClosePermissionModal,
  onRequestLocationPermission,
  onOpenPermissionModal,
  onOpenBottomDrawer,
  history,
  delivery,
  products,
  isEPNFacilityUser,
  isDeliveryReceived,
  shipmentId,
  snapshotId
}) => {
  const location = useLocation()

  const prevPath = get(location, 'state.prevPath', '')
  const fromHomeRoute = prevPath === '/'

  const formattedDate = formatDate(delivery.date, 'long')
  const driverName = get(delivery, 'updatedBy.user', '') // TODO: get driver's name
  const isDeliveryDelayed = delivery.status === SHIPMENT_STATUS.DELAYED

  const { location: facilityName } = getPSMDriverDeliveryTitle(delivery)
  const displayOTP = otp && !isDeliveryReceived

  let title = 'Deliveries'
  let icon = <Truck />
  if (fromHomeRoute) {
    title = 'Home'
    icon = <Home />
  }

  let tableHeader = 'The following products are scheduled for delivery'
  if (isDeliveryReceived) {
    tableHeader = 'The following products have been delivered'
  }

  const onHandleConfirmLocation = () => {
    if (permission === PERMISSION_STATES.PROMPT) {
      onOpenPermissionModal()
    } else {
      onOpenBottomDrawer()
    }
  }

  return (
    <Page>
      <Page.HeaderNew
        title={title}
        icon={icon}
        history={history}
        subtitle='Delivery'
      />

      <Page.Panel narrow alignLeft>
        <Page.Panel.Section>
          <section className='facility-delivery-detail__header-info'>
            <StatusPill
              className={`delivery-location__status--${delivery.status}`}
              label='Status'
              value={capitalize(SHIPMENT_STATUS_TO_LMD_DISPLAY_STATUS[delivery.status])}
            />
            <StatusPill
              label='Date'
              value={formattedDate}
            />
            <StatusPill
              label='Driver'
              value={driverName || 'TBD'}
            />
            <StatusPill
              label='ID'
              value={delivery.shipmentNo || 'TBD'}
            />
          </section>

          {isDeliveryReceived && (
            <Button
              className='vs-u-margin-top'
              component={Link}
              to={`/shipments/last-mile-deliveries/delivery-statement/${snapshotId}`}
              colorVariant='brand'
              fill='outline'
            >
              View delivery note
            </Button>
          )}
        </Page.Panel.Section>
      </Page.Panel>

      {isDeliveryDelayed && (
        <Page.Panel narrow alignLeft withMinimalMargin>
          <Banner
            title='Temporary delay in delivery'
            center={false}
            inline
          >
            We regret to inform you of delays in your order delivery. We appreciate your understanding during this time.
          </Banner>
        </Page.Panel>
      )}

      {displayOTP && (
        <Page.Panel narrow alignLeft withMinimalMargin>
          <section className='facility-delivery-detail__otp-info'>
            <Text>To receive this delivery, please provide the OTP directly to the driver in person.</Text>
            <Text
              className='facility-delivery-detail__otp'
              color='brand'
              bold
            >
              Your OTP is: {otp}
            </Text>
          </section>
        </Page.Panel>
      )}

      <Page.Panel narrow alignLeft withMinimalMargin={!displayOTP}>
        <section className='facility-delivery-detail__table'>
          <Text className='vs-u-margin-bottom-double'>{tableHeader}:</Text>

          <div className='facility-delivery-detail__table-header'>
            <UppercaseLabel>Products</UppercaseLabel>
            <UppercaseLabel>Qty</UppercaseLabel>
          </div>

          <Accordion>
            {products.map(product => (
              <DeliveryDetailProduct
                key={product._id}
                product={product}
              />
            ))}
          </Accordion>
        </section>
      </Page.Panel>

      <PSMDeliveryAuthenticationContainer
        api={api}
        otpKey={otpKey}
        skipGPSValidation={skipGPSValidation}
        history={history}
        shipmentId={shipmentId}
        snapshotId={snapshotId}
        isBottomDrawerOpen={isBottomDrawerOpen}
        permission={permission}
        geoPosition={geoPosition}
        position={position}
        distance={distance}
        facilityName={facilityName}
        locationObject={locationObject}
        positionErrorMsg={positionErrorMsg}
        onClose={onCloseBottomDrawer}
      />

      {!isDeliveryReceived && isEPNFacilityUser && (
        <Page.Footer fixed nowrap>
          <Button
            colorVariant='brand'
            fill='full'
            onClick={onHandleConfirmLocation}
          >
            Confirm delivery
          </Button>
        </Page.Footer>
      )}

      <DriverDeliveryPermissionModal
        isPermissionModalOpen={isPermissionModalOpen}
        isRequestingPermission={isRequestingPermission}
        onClosePermissionModal={onClosePermissionModal}
        onRequestLocationPermission={onRequestLocationPermission}
      />
    </Page>
  )
}

export default FacilityDeliveryDetail
